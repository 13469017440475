import { RelayEnvironmentProvider } from "react-relay";
import { RelayEnvironment } from "./RelayEnvironment";
import React from "react";
import ReactDOM from "react-dom";

import "./index.css";
import "./stylesheets/site.css.scss";
import App from "./App";

import $ from "jquery";

$("#loading").show();

const rootEl = document.getElementById("root");
if (document.getElementById("root")) {
  ReactDOM.render(
    <React.StrictMode>
      <RelayEnvironmentProvider environment={RelayEnvironment}>
        <App />
      </RelayEnvironmentProvider>
    </React.StrictMode>,
    rootEl
  );
}
