import $ from "jquery";
import React, { Suspense, lazy, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";
import Header from "./components/Header";
import HelpText from "./components/HelpText";

import { Collective } from "./types/Collective";
import store from "./redux/store";
import ErrorBoundary from "./components/ErrorBoundary";
import { Member } from "./types/Member";

const AccountData = lazy(() => import("./pages/AccountData/AccountData"));
const AccountPassword = lazy(() => import("./pages/AccountPassword/AccountPassword"));
const Contact = lazy(() => import("./pages/Contact"));
const DocumentsList = lazy(() => import("./pages/Documents/DocumentsList"));
const Home = lazy(() => import("./pages/Home"));
const ProjectFieldList = lazy(() => import("./pages/ProjectFields/ProjectFieldsList"));
const ReportsList = lazy(() => import("./pages/Reports/ReportsList"));
const MapPage = lazy(() => import("./pages/Map/Map"));
const KPIsList = lazy(() => import("./pages/KPI/KPIPage"));
const KPIAuthorizationOverview = lazy(
  () => import("./pages/KPI/Authorization/KPIAuthorizationOverview")
);

declare global {
  interface Window {
    pageName?: string | undefined;
    years?: [string] | undefined;
    apps?: [Collective] | undefined;
    members?: [Member] | undefined;
    currentUsername?: string | undefined;
    topBarLoggedOut?: boolean | undefined;
    kpisEnabled?: boolean | undefined;
    kpiAuthorizationsEnabled?: boolean | undefined;
  }
}

function App() {
  const {
    pageName,
    years,
    apps,
    members,
    currentUsername,
    topBarLoggedOut,
    location,
    kpisEnabled,
    kpiAuthorizationsEnabled,
  } = window;

  const [helpToggled, toggleHelp] = useState(false);
  const [currentYear, currentApp] = useMemo(
    () => location.pathname.split("/").slice(1, 5),
    [location.pathname]
  );
  useEffect(() => {
    $("#loading").hide();
  }, []);
  return (
    <BrowserRouter basename={`/${currentYear}/${currentApp}/`}>
      <Header
        loggedOut={topBarLoggedOut}
        allApps={apps || []}
        allMembers={members || []}
        allYears={years || ["2020"]}
        currentUsername={currentUsername || "user"}
        currentYear={parseInt(currentYear || "2020")}
        currentApp={currentApp || "scan_demo"}
        onToggleHelp={() => toggleHelp(!helpToggled)}
      />
      {pageName?.indexOf("wachtwoord") === -1 && pageName !== "login" ? (
        <HelpText helpToggled={helpToggled} onToggleHelp={() => toggleHelp(!helpToggled)} />
      ) : null}
      <Provider store={store}>
        <Switch>
          <ErrorBoundary>
            <Suspense
              fallback={
                <div className="loading-container">
                  <div className="c-loading"></div>
                </div>
              }
            >
              <Route path="/home">
                <Home currentApp={currentApp} />
              </Route>
              <Route path="/kaart">
                <MapPage currentYear={currentYear} currentApp={currentApp} />
              </Route>
              <Route path="/percelen">
                <ProjectFieldList currentYear={currentYear} currentApp={currentApp} />
              </Route>
              <Route path="/meldingen">
                <ReportsList currentYear={currentYear} currentApp={currentApp} />
              </Route>
              <Route path="/documenten">
                <DocumentsList currentYear={currentYear} currentApp={currentApp} />
              </Route>
              {kpisEnabled ? (
                <Route path="/kpis">
                  <KPIsList currentYear={currentYear} currentApp={currentApp} />
                </Route>
              ) : null}
              {kpiAuthorizationsEnabled ? (
                <Route path="/machtigingen">
                  <KPIAuthorizationOverview year={Number(currentYear)} />
                </Route>
              ) : null}
              <Route path="/contact">
                <Contact currentYear={currentYear} currentApp={currentApp} />
              </Route>
              <Route path="/account">
                <AccountPassword />
              </Route>
              <Route path="/gegevens">
                <AccountData />
              </Route>
            </Suspense>
          </ErrorBoundary>
        </Switch>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
